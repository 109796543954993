import { FormEvent, useId, useState } from 'react';
import { LoginProps } from './types';

export const Login = (props: LoginProps) => {
    const userId = useId();
    const passId = useId();
    const tokenId = useId();

    const [userInput, setUserInput] = useState('');
    const [passInput, setPassInput] = useState('');
    const [tokenInput, setTokenInput] = useState('');

    const handleLoginClick = () => {
        retrieveAccessToken();
    }

    const handleSetTokenClick = () => {
        props.onSetTokenClick(tokenInput);
    }

    const retrieveAccessToken = () => {
        let data = {
            'AuthParameters': {
                'USERNAME': userInput,
                'PASSWORD': passInput,
            },
            'AuthFlow': 'USER_PASSWORD_AUTH',
            'ClientId': '4slg6ne4j7a4kbboabaptvajqa',
        };
        fetch('https://cognito-idp.eu-central-1.amazonaws.com', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-amz-json-1.1',
                'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
            },
            body: JSON.stringify(data),
        }).then((response) => response.json())
            .then((data) => {
                if (data.AuthenticationResult?.AccessToken) {
                    setTokenInput(data.AuthenticationResult.AccessToken);
                } else {
                    console.log(`Error: '${data.message}'`);
                }
            }).catch((ignored) => {
                console.error(ignored);
            })
    }

    return (
        <div>
            <div className='line headSpace'>
                <input
                    id={userId}
                    value={userInput}
                    onInput={(e: FormEvent<HTMLInputElement>) => setUserInput((e.target as HTMLInputElement).value)}
                    placeholder='Username' />
                <input
                    id={passId}
                    value={passInput}
                    onInput={(e: FormEvent<HTMLInputElement>) => setPassInput((e.target as HTMLInputElement).value)}
                    type='password' placeholder='Password' />
                <button
                    className='switch'
                    onClick={handleLoginClick}>Login
                </button>
            </div>
            <div className='line'>
                <input
                    id={tokenId}
                    value={tokenInput}
                    onInput={(e: FormEvent<HTMLInputElement>) => setTokenInput((e.target as HTMLInputElement).value)}
                    placeholder='Token' />
                <button
                    className='switch'
                    onClick={handleSetTokenClick}>Set token
                </button>
            </div>
        </div>
    );
}

export default Login;
