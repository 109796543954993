import { FormEvent, useId, useState } from 'react';
import { StatePickerProps } from './types';

export const StatePicker = (props: StatePickerProps) => {
    const id = useId();
    const [input, setInput] = useState(props?.chatTarget ?? '');

    const handleClick = () => {
        props.onClick(input);
    }

    const keyDown = (event: { key: string }) => {
        if (event?.key === 'Enter') {
            handleClick();
        }
    }

    return (
        <div className='line'>
            <input
                id={id}
                value={input}
                onInput={(e: FormEvent<HTMLInputElement>) =>
                    setInput((e.target as HTMLInputElement).value)}
                onKeyDown={keyDown}
                placeholder='Target state' />
            <button
                className="switch"
                onClick={handleClick}>Set chat state
            </button>
        </div>
    );
}

export default StatePicker;
