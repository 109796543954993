import { ChatProps } from "../chat/types";

export const Display = (props: ChatProps) => {

    return (
        <div className='display'>
            <h2>Current chat properties</h2>
            <ul>
                <li>enabled: {`${props.chat_enabled}`}</li>
                <li>popup: {`${props.chat_popup_enabled}`}</li>
                <li>target: {`${props.chat_target}`}</li>
                <li>logout: {`${props.chat_logout}`}</li>
                <li>token: {props.chat_token}</li>
            </ul>
        </div>
    );
}

export default Display;
