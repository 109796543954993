import { SwitchProps } from "./types";

export const Switch = (props: SwitchProps) => {

    return (
        <button
            className="switch"
            onClick={props.onClick}>{props.caption}
        </button>
    );
}

export default Switch;
