import React from 'react';
import Display from '../display/display';
import Switch from '../switch/switch';
import StatePicker from '../state-picker/statePicker';
import Login from '../login/login';
import Chat from '../chat/chat';
import { AppProps } from './types';
import { ChatProps } from '../chat/types';

class App extends React.Component<AppProps, ChatProps> {
    constructor(props: AppProps) {
        super(props);
        this.state = {
            chat_enabled: true,
            chat_popup_enabled: false,
            chat_target: '',
            chat_token: '',
            chat_logout: false,
        };
    }

    togglePopup() {
        this.setState({ chat_popup_enabled: !this.state.chat_popup_enabled });
    }

    toggleChat() {
        this.setState({ chat_enabled: !this.state.chat_enabled });
    }

    toggleLogout() {
        this.setState({ chat_logout: !this.state.chat_logout });
    }

    chatTarget(newTarget: string) {
        this.setState({ chat_target: newTarget });
    }

    token(newToken: string) {
        this.setState({ chat_token: newToken })
    }

    render() {
        return (
            <div className='hostApp'>
                <h2>Manipulate chat properties</h2>
                <div className='line'>
                    <Switch
                        caption='Toggle Enabled'
                        onClick={() => this.toggleChat()}
                    />
                    <Switch
                        caption='Toggle Popup'
                        onClick={() => this.togglePopup()}
                    />
                    <Switch
                        caption='Toggle Logout'
                        onClick={() => this.toggleLogout()}
                    />
                </div>
                <div>
                    <StatePicker
                        chatTarget={this.state.chat_target}
                        onClick={(newTarget: string) => this.chatTarget(newTarget)}
                    />
                </div>
                <Login
                    onSetTokenClick={(newToken: string) => this.token(newToken)}
                />
                <div className='headSpace'>
                    <Chat
                        chat_enabled={this.state.chat_enabled}
                        chat_popup_enabled={this.state.chat_popup_enabled}
                        chat_target={this.state.chat_target}
                        chat_token={this.state.chat_token}
                        chat_logout={this.state.chat_logout}
                    />
                </div>
                <Display
                    chat_enabled={this.state.chat_enabled}
                    chat_popup_enabled={this.state.chat_popup_enabled}
                    chat_target={this.state.chat_target}
                    chat_token={this.state.chat_token}
                    chat_logout={this.state.chat_logout}
                />
            </div>
        );
    }
}

export default App;
