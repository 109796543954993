import { useEffect } from 'react';
import { ChatProps } from './types';

export const Chat = (props: ChatProps) => {
    let target = props.chat_target;
    const emitTarget = () => {
        const validTargets = [
            "chat",
            "authenticate",
            "iban",
            "bankDetail",
            "relocation",
            "advancePayRecommendation",
            "dryingMeasure",
            "contactData",
            "contactData_invoiceAddress",
            "inbox",
            "bonus",
            "product",
            "login",
            "resetPassword",
            "advancePay",
            "advancePay_amountOnly",
            "advancePay_dayOnly",
            "interimInvoice",
            "invoiceInterval",
            "invoiceInterval_restricted",
            "invoiceDispatch",
            "earliestPossibleSupply",
            "readingCorrection",
            "activeBillPlan",
        ];
        if (validTargets.indexOf(props.chat_target) < 0) {
            target = "chat";
            console.log("Invalid target state");
        }
        document.querySelector("#chatWindow")?.dispatchEvent(
            new CustomEvent("enableOpen", { detail: { target } }));
    }

    const emitToken = () => {
        document.querySelector("#chatWindow")?.dispatchEvent(
            new CustomEvent("setToken", { detail: { token: props.chat_token } }));
    }

    const emitLogout = () => {
        document.querySelector("#chatWindow")?.dispatchEvent(new Event("hostLogout"));
    }

    const emitEvents = () => {
        if (props.chat_enabled) {
            emitTarget();
        }
        if (props.chat_logout) {
            emitLogout();
        }
        else if (props.chat_token) {
            emitToken();
        }
        if (props.chat_popup_enabled) {
            document.querySelector("#chatWindow")?.dispatchEvent(new Event("open"));
        }
    }

    useEffect(() => {
        if (!props.chat_enabled) {
            document.querySelector("#chatWindow")?.dispatchEvent(new Event("keepClosed"));
            return;
        }

        const scriptPresent = !!document.querySelector("#bootstrapChatScript");
        if (!(window as any).bootstrapChat && !scriptPresent) {
            const runtimeUrl = "https://test.sophie-chatbots.net/"
            let hostUrl = "https://test.sophie-chatbots.net/3.0/";
            // if (window.location.origin.includes("localhost")) {
            //     hostUrl = "http://localhost:4300/";
            // }
            const script = document.createElement("script");
            script.id = "bootstrapChatScript";
            script.src = hostUrl + "bootstrapChat.js";
            script.onload = function () {
                (window as any).bootstrapChatForConfig({
                    hostUrl,
                    runtimeUrl,
                    hostOpen: props.chat_popup_enabled,
                    configurationId: "",
                    target,
                    token: props.chat_token,
                    logout: props.chat_logout,
                });
            };
            document.head.appendChild(script);

        } else {
            emitEvents();
        }
    });

    return (
        <button
            className="chatInitiator"
        >Chat</button>
    );
}

export default Chat;
